import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TentTypeV1 } from '@camp67/model';
import { Modal } from '../policy-modal';
import { Amenity } from '../amenity';
import { useLocation, useNavigate } from '@remix-run/react';
import { tents } from '../../constants/tents';
import { ModalCloseButton } from './modal-close-button';
import Sprite from '../../sprites/sprite';
import { displayPrice } from '../../state/prices';
import { getPrice } from '@camp67/model/booking/price';
import { reservationStore } from '../../state/reservation-store';
import { useMessages } from '../../i18n/useMessages';

interface TentExperienceModalProps {
  tentType: TentTypeV1;
  onClose: VoidFunction;
  availability?: TentTypeV1[];
  canChooseAccommodation?: boolean;
}

export const TentExperienceModal: React.FC<TentExperienceModalProps> = ({
  tentType,
  onClose,
  availability,
  canChooseAccommodation = true,
}) => {
  const allTents = availability ?? (Object.keys(tents) as TentTypeV1[]);

  const [type, setType] = useState<TentTypeV1>(tentType);
  const {
    gallery,
    displayName,
    title: titleKey,
    description: descriptionKey,
    amenities,
    guests: guestsKey,
  } = tents[type];

  const getMessage = useMessages();

  const title = getMessage(titleKey);
  const description = getMessage(descriptionKey);
  const guests = getMessage(guestsKey);
  const perGuest = getMessage('camp67-accommodation.price-per-guest');
  const callToLearnMore = getMessage('camp67-accommodation.call-to-learn-more');
  const chooseAccommodation = getMessage('camp67-accommodation.choose-accommodation');
  const viewNext = getMessage('camp67-accommodation.view-next');

  const price = displayPrice({ priceInINR: getPrice(type) });

  const [image, setImage] = useState(0);

  useEffect(() => {
    const bodyWidth = document.body.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.width = `${bodyWidth}px`;
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.width = 'auto';
    };
  }, [tentType]);

  const onClickNext = useCallback(() => {
    setImage(0);
    setType(allTents[(allTents.indexOf(type) + 1) % allTents.length]);

    const modalContent = document.getElementById('tent-experience-modal-content');
    if (!modalContent) return;
    modalContent.scrollTo({ top: 0, behavior: 'smooth' });
  }, [allTents, type]);

  const onClickGalleryNext = useCallback(() => {
    setImage((prev) => (prev + 1) % gallery.length);
  }, [gallery]);

  const onClickGalleryPrev = useCallback(() => {
    setImage((prev) => (prev - 1) % gallery.length);
  }, [gallery]);

  const imageUrl = useMemo(() => gallery[image].imageUrl, [image, gallery]);
  const location = useLocation();
  const navigate = useNavigate();

  const onClickChooseAccommodation = useCallback(() => {
    reservationStore.clearRooms();
    reservationStore.addRoom(type);

    onClose();

    if (location.pathname === '/') {
      navigate(`/packages/reservation?tentType=${encodeURIComponent(type)}`);
    }
  }, [onClose, type, location, navigate]);

  return (
    <Modal isOpen={!!type} onClose={onClose}>
      <div
        className="bg-white w-[95%] md:w-8/12 lg:w-7/12 h-[95%] z-20 relative m-auto flex flex-col items-center justify-start rounded-2xl"
        id="tent-experience-modal-content"
      >
        <ModalCloseButton onClose={onClose} />
        <div className="overflow-y-auto w-full relative">
          <hgroup className="pt-8 px-12 block">
            <div className="flex flex-col gap-3 md:flex-row items-start w-full md:justify-between">
              <div className="flex flex-row items-center flex-wrap gap-3 text-nowrap whitespace-nowrap">
                <p className="m-auto sm:m-[unset] block text-center sm:text-left font-sans text-lg text-black font-bold text-nowrap whitespace-nowrap">
                  {displayName || title}
                </p>
                <span className="flex flex-row gap-1 items-center text-lg">
                  <Sprite name="person" width="20" height="20" className="w-4 h-4" />
                  {guests}
                </span>
              </div>
              <p className="md:text-lg md:mt-0 mt-1 font-sans text-nowrap whitespace-nowrap">
                {price} {perGuest}
              </p>
            </div>
            <p className="text-sm md:text-base mt-1 text-left">{description}</p>
          </hgroup>
          <div className="w-full relative">
            <img
              alt={`The inside of the ${type} tent offered by camp67.`}
              loading="lazy"
              src={imageUrl}
              className="object-cover w-full max-h-120 mt-6 pointer-events-none select-none"
            />
            {gallery.length > 1 ? (
              <>
                <button
                  onClick={onClickGalleryPrev}
                  type="button"
                  className="absolute left-12 bottom-8 md:bottom-12 bg-white w-10 h-10 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center"
                >
                  <Sprite
                    name="prev"
                    strokeWidth="3"
                    width="12"
                    height="21"
                    className="w-2 md:w-3"
                  />
                </button>
                <button
                  onClick={onClickGalleryNext}
                  type="button"
                  className="absolute right-12 bottom-8 md:bottom-12 bg-white w-10 h-10 md:w-12 md:h-12 rounded-full flex flex-col items-center justify-center"
                >
                  <Sprite
                    name="next"
                    strokeWidth="3"
                    width="12"
                    height="21"
                    className="w-2 md:w-3"
                  />
                </button>
              </>
            ) : null}
          </div>
          <div className="py-8 px-12">
            <div className="flex flex-col lg:flex-row items-center lg:justify-between flex-wrap gap-6">
              <span className="text-base text-center md:text-left">
                {callToLearnMore}
              </span>
              <div className="flex flex-col items-center md:flex-row gap-6 w-full md:w-[unset]">
                {canChooseAccommodation ? (
                  <button
                    type="button"
                    onClick={onClickChooseAccommodation}
                    className="w-full xl:w-[unset] px-7 py-5 bg-green-200 rounded-md text-nowrap whitespace-nowrap"
                  >
                    <span className="text-sm tracking-wider uppercase font-bold text-gray-500">
                      {chooseAccommodation}
                    </span>
                  </button>
                ) : null}
                <button
                  type="button"
                  onClick={onClickNext}
                  className="w-full md:w-[unset] md:px-7 py-5 bg-gray-100 rounded-md text-nowrap whitespace-nowrap"
                >
                  <span className="text-sm tracking-wider uppercase font-bold text-gray-500">
                    {viewNext}
                  </span>
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">
              {amenities.tentAmenities.map((tentAmenity, index) => (
                <Amenity appearance="dialog" {...tentAmenity} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
